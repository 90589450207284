<template>
  <div class="d-flex tag-input align-center flex-wrap">
    <v-chip
      @click:close="removeTag(index)"
      v-for="(tag, index) in tags"
      :key="index"
      close
      class=""
    >
      {{ tag }}
    </v-chip>

    <v-text-field
      @keydown.enter="addTag"
      @keydown.space="addTag"
      @keydown.delete="removeLastTag"
      dense
      solo
      hide-details
      v-model="tag"
      flat
      label="Enter a Tag"
    >
    </v-text-field>
  </div>
</template>
<script>
export default {
  name: "tagInput",
  props: {
    image: Object,
    value: Boolean
  },
  data() {
    return {
      tags: [],
      tag: "",
      rules: {
        required: v => !!v || "Required",
        value: v => !v || v.size > 0 || "Required"
      }
    };
  },
  watch: {
    value: function() {
      this.tags = [];
      this.image.tags = this.tags;
    }
  },
  methods: {
    addTag(event) {
      event.preventDefault();
      var val = event.target.value.trim().toLowerCase();
      if (val.length > 0) {
        this.tags.push(val);
        event.target.value = "";
        this.tag = "";
      }
      this.image.tags = this.tags;
    },
    removeTag(index) {
      this.tags.splice(index, 1);
    },
    removeLastTag(event) {
      if (event.target.value.length === 0) {
        this.removeTag(this.tags.length - 1);
      }
    }
  }
};
</script>
<style scoped>
.tag-input {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 0.9em;

  box-sizing: border-box;
  padding: 0 5px;
}

.tag-input__tag {
  height: 30px;
  margin-right: 5px;
  float: left;
  background-color: #eee;
  line-height: 30px;
  padding-right: 5px;
  border-radius: 5px;
}

.tag-input__text {
  border: none;
  outline: none;
  font-size: 0.9em;
  line-height: 50px;
  background: none;
}
</style>
