<template>
    <v-dialog v-model="show" persistent max-width="400px">
        <v-card :loading="loading">
            <v-card-title class="text-h5">
                <v-btn
                    class="mr-3"
                    depressed
                    color="primary"
                    icon
                    rounded
                    @click="closeDialog"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>

                {{ title }}</v-card-title
            >
            <v-card-text>
                <v-form ref="formImage" v-model="valid">
                    <v-row>
                        <v-col v-if="!setting || pdfImage" cols="12">
                            <TagInput :image="image" :value="value"> </TagInput>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-file-input
                                v-model="files"
                                type="file"
                                accept="image/*"
                                label="Image"
                                @change="onFilePicked"
                                prepend-icon="mdi-file-image"
                                :rules="[rules.value]"
                                required
                            ></v-file-input>
                        </v-col>
                    </v-row>
                </v-form>
                <small v-if="pdfImage">* Max image size 2200x1300px</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    v-if="tool"
                    text
                    color="primary"
                    :loading="loading"
                    :disabled="!valid"
                    @click="upload"
                >
                    Upload
                </v-btn>
                <v-btn
                    v-if="setting && !pdfImage"
                    text
                    color="primary"
                    :loading="loading"
                    :disabled="!valid"
                    @click="uploadLogo"
                >
                    Upload
                </v-btn>
                <v-btn
                    v-if="setting && pdfImage"
                    text
                    color="primary"
                    :loading="loading"
                    :disabled="!valid"
                    @click="uploadPdfImage"
                >
                    Upload
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import API from '@/services/api'
import { storage } from '@/services/firebase'
import TagInput from '@/components/Settings/TagInput'
import { mapActions, mapMutations } from 'vuex'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
export default {
    name: 'ImageForm',
    components: {
        TagInput,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        setting: Object,
        tool: Object,
        pdfImage: Boolean,
        pdfImages: Object,
        value: Boolean,
    },
    data() {
        return {
            company: JSON.parse(localStorage.getItem('company')),
            folder: 'photo_galery',
            folderLogo: 'company_logo',
            folderPdfImage: 'pdf_images',
            loading: false,
            image: {},
            error: false,
            errorMsg: null,
            valid: false,
            rules: {
                required: v => !!v || 'Required',
                value: v => !v || v.size > 0 || 'Required',
            },
            deleteDialog: false,
            deleteError: false,
            files: [],
        }
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
    },

    methods: {
        ...mapActions(['newCommercialImageAction']),
        ...mapMutations(['setErrorItems']),
        onFilePicked: async function(event) {
            if (event !== undefined) {
                const nameFile = uuidv4()
                this.image.file = nameFile + '.' + event.name.split('.').pop()
                if (this.pdfImage) {
                    const url = URL.createObjectURL(event)
                    const img = await this.loadImage(url)
                    await this.resizeImage(img)
                }
            }
        },
        makeid() {
            var length = 6
            var result = ''
            var characters =
                'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
            var charactersLength = characters.length
            for (var i = 0; i < length; i++) {
                result += characters.charAt(
                    Math.floor(Math.random() * charactersLength)
                )
            }
            return result.toUpperCase()
        },
        resizeImage: async function(img) {
            let { height, width } = img
            const maxH = 1300
            const maxW = 2500

            if (height > maxH || width > maxW) {
                this.error = true
                this.errorMsg = "the image doesn't have the requirements"
                this.valid = false
            } else {
                this.error = false
                this.valid = true
            }
        },
        loadImage: function(src) {
            return new Promise(resolve => {
                const img = new Image()
                img.src = src
                img.onload = () => resolve(img)
            })
        },
        closeDialog: function() {
            this.$refs.formImage.reset()
            this.$emit('closeDialog')
            this.error = false
            this.errorMsg = null
        },
        upload: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const path = `${this.company}/${this.folder}`
                const storageRef = storage().ref(path)
                const profilePicRef = storageRef.child(this.image.file)
                var metadata = {
                    customMetadata: {
                        filename: this.files.name,
                    },
                }
                await profilePicRef.put(this.files, metadata)
                const newId = this.makeid()
                const arr = this.image.file.split('.')
                const file320x320 = arr[0] + '_320x320.' + arr[1]
                const profilePic320x320Ref = storageRef.child(file320x320)
                const file = {
                    id: newId,
                    tags: this.image.tags ? this.image.tags : [],
                    file: this.image.file,
                    file320x320,
                }
                const {
                    data: { commercialImage },
                } = await API.newCommercialImages(file)
                await profilePicRef
                    .getDownloadURL()
                    .then(URL => {
                        commercialImage.imageURL = URL
                    })
                    .catch(error => {
                        commercialImage.imageURL = ''
                        error && true
                    })
                setTimeout(async () => {
                    await profilePic320x320Ref
                        .getDownloadURL()
                        .then(URL => {
                            commercialImage.image320x320URL = URL
                        })
                        .catch(error => {
                            commercialImage.image320x320URL = ''
                            error && true
                        })
                    commercialImage.show = true
                    this.newCommercialImageAction(commercialImage)
                    this.closeDialog()
                    this.loading = false
                }, 6000)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        toDataUrl(url, callback) {
            var xhr = new XMLHttpRequest()
            xhr.onload = function() {
                var reader = new FileReader()
                reader.onloadend = function() {
                    callback(reader.result)
                }
                reader.readAsDataURL(xhr.response)
            }
            xhr.open('GET', url)
            xhr.responseType = 'blob'
            xhr.send()
        },
        uploadLogo: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const path = `${this.company}/${this.folderLogo}`
                const storageRef = storage().ref(path)
                const profilePicRef = storageRef.child(this.image.file)
                var metadata = {
                    customMetadata: {
                        filename: this.files.name,
                    },
                }
                await profilePicRef.put(this.files, metadata)
                const file = {
                    name: this.image.name,
                    file: this.image.file,
                }
                this.setting.generalInfo.image = file
                file.imageURL = await profilePicRef.getDownloadURL()
                await this.toDataUrl(file.imageURL, function(myBase64) {
                    this.setting.generalInfo.logoBase64 = myBase64
                })
                await API.updateSetting(this.setting.id, this.setting)
                this.setting.generalInfo.image = file
                //this.$emit("addImage", file);
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        uploadPdfImage: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const path = `${this.company}/${this.folderPdfImage}`
                const storageRef = storage().ref(path)
                const profilePicRef = storageRef.child(this.image.file)
                var metadata = {
                    customMetadata: {
                        filename: this.files.name,
                    },
                }
                await profilePicRef.put(this.files, metadata)
                const newId = this.makeid()
                const file = {
                    id: newId,
                    tags: this.image.tags,
                    file: this.image.file,
                }
                const { id } = this.pdfImages
                if (this.pdfImages.images) {
                    const pdfImages = _.cloneDeep(this.pdfImages.images)
                    pdfImages.forEach(i => {
                        delete i.imageURL
                        delete i.show
                    })
                    pdfImages.push(file)
                    await API.updateImages(this.setting.id, {
                        id: id,
                        images: pdfImages,
                    })
                } else {
                    this.pdfImages.images = []
                    const pdfImages = _.cloneDeep(this.pdfImages.images)
                    pdfImages.push(file)
                    await API.updateImages(this.setting.id, {
                        id: id,
                        images: pdfImages,
                    })
                }

                file.imageURL = await profilePicRef.getDownloadURL()
                file.show = true
                this.$emit('addImage', file)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.closeDialog()
            }
        },
    },
}
</script>
